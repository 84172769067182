import React from 'react'
import styled from 'styled-components/macro'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import ProfilePic from '../img/Rebecca_prog.jpg'
import { FaGithub, FaLinkedin, FaXingSquare, FaInstagram } from 'react-icons/fa'

export default function Contact() {
  return (
    <Layout pageTitle="Contact">
      <main>
        <ProfilePicStyled
          src={ProfilePic}
          loading="lazy"
          alt="Profile picture of Rebecca"
          width="6000"
          height="4000"
        />
        <ContactIntroContainer>
          <div>
            <p>Ah, got you interested? Great!</p>
            <p>
              Whether you're interested to hire on me on short- or long-term
              projects or would like to collaborate on some other topic, I'd
              love get in touch with you!
              <br />
              Send me a message using the form below.
            </p>
          </div>
          <div>
            <p>You can also visit me on: </p>
            <SocialPlatformIcons>
              <li>
                <a
                  href="https://www.linkedin.com/in/rebecca-frey-8205263a/"
                  rel="noreferrer"
                  target="blank"
                  title="Rebecca's LinkedIn profile"
                >
                  <span>
                    <FaLinkedin size="32" />
                  </span>
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.xing.com/profile/Rebecca_Frey5/"
                  rel="noreferrer"
                  target="blank"
                  title="Rebecca's XING profile"
                >
                  <span>
                    <FaXingSquare size="32" />
                  </span>
                  XING
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/rebeccafrey"
                  rel="noreferrer"
                  target="blank"
                  title="Rebecca's GitHub profile"
                >
                  <span>
                    <FaGithub size="32" />
                  </span>
                  GitHub
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/soulcoding/"
                  rel="noreferrer"
                  target="blank"
                  title="Rebecca's Instagram profile"
                >
                  <span>
                    <FaInstagram size="32" />
                  </span>
                  Instagram
                </a>
              </li>
            </SocialPlatformIcons>
          </div>
        </ContactIntroContainer>
        <h2>Drop me a line right here:</h2>
        <p className="italic">(English or German, your choice)</p>
        <ContactForm />
      </main>
    </Layout>
  )
}

const ContactIntroContainer = styled.div`
  margin-bottom: 1.5em;

  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin-left: 20px;
  }

  p {
    font-size: 1.8rem;
  }
`
const ProfilePicStyled = styled.img`
  margin-bottom: 20px;
  width: 100%;
  height: auto;
`
const SocialPlatformIcons = styled.ul`
  list-style: none;
  line-height: 1.5;
  vertical-align: middle;

  > li > a {
    line-height: 1.5;
  }

  > li > a > span {
    height: 100%;
    padding: 0 8px 0 16px;
    vertical-align: middle;
    color: var(--primary-light);
  }
`
