import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'gatsby'
import Button from './Button'

export default function ContactForm() {
  return (
    <form
      name="contact"
      method="post"
      action="/thanks"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />

      <div>
        <label htmlFor="name">
          Please let me know your name<RequiredStyled>*</RequiredStyled>:
        </label>
        <ShortInputStyled
          type="text"
          name="name"
          id="name"
          className="input__style"
          placeholder="e.g. (unlikely) The Doctor, Amy Pond, Jack Harness, Rose Tyler, River Song, Rory Williams..."
          required
        />
      </div>
      <div>
        <label htmlFor="email">
          How can I reach you via email?<RequiredStyled>*</RequiredStyled>
        </label>
        <ShortInputStyled
          type="email"
          name="email"
          id="email"
          className="input__style"
          placeholder="here@there.de"
          required
        />
      </div>
      <div>
        <label htmlFor="message">
          Drop me a line<RequiredStyled>*</RequiredStyled>:
        </label>
        <LongInputStyled
          name="message"
          id="message"
          className="input__style"
          placeholder="Your request, message, question, inspiration..."
          required
        />
      </div>
      <SmallPrint>
        <RequiredStyled>*</RequiredStyled>required
      </SmallPrint>
      <p>
        By sending off your message, you agree to the{' '}
        <Link to="/datenschutz">privacy policy</Link>.
      </p>
      <Button type="submit">Send carrier pigeon</Button>
    </form>
  )
}

const ShortInputStyled = styled.input`
  margin: 2px auto 20px auto;
`
const LongInputStyled = styled.textarea`
  height: 180px;
  margin: 2px auto 0 auto;
  overflow-y: scroll;
`
const SmallPrint = styled.p`
  font-size: 14px;
`
const RequiredStyled = styled.span`
  color: red;
`
